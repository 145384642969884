@import 'variables';

:root {
  --cui-primary-light: #4db1fd;
  --cui-aside-handle-bg: #192940;
}

.form-multi-select-option.form-multi-selected {
  background-color: $primary;
  color: #fff;
}

.text-center-sm {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.mt-3-sm {
  @include media-breakpoint-down(sm) {
    margin-top: 1rem !important;
  }
}

.body,
.header {
  margin-left: 3rem;
  margin-right: 3rem;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-right: 0;
  }
}

.sidebar {
  box-shadow: none;

  &.sidebar-fixed {
    box-shadow: none;
  }
  &.border {
    border-left: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-right: 1px solid $gray-300;
  }
  &.border-left {
    border-left: 1px solid $gray-300;
  }
}

.sidebar-nav-none {
  .sidebar-fixed {
    flex: 0 0 $sidebar-brand-height;
    width: $sidebar-brand-height;
    border: 0;
  }
  .wrapper {
    @include media-breakpoint-up(sm) {
      padding-left: 0 !important;
    }
    @include media-breakpoint-up(md) {
      padding-left: $sidebar-brand-height !important;
    }
  }
}

.sidebar-nav {
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-nav .nav-group-toggle::after {
  display: none;
}

.sidebar-header {
  text-align: left;
  padding-top: 1.9rem !important;
  padding-bottom: 1.9rem !important;
  background-color: transparent;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--cui-primary);
  font-size: 0.875rem;
  font-weight: 700;
  height: 0px !important;
}

.aside .sidebar-header {
  text-align: left;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  background-color: transparent;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--cui-primary);
  font-size: 0.875rem;
  font-weight: 700;
  flex: 0 0 5rem;
}

.border-primary {
  border-color: var(--cui-primary) !important;
}

.sidebar-toggler {
  display: inline-block;
  position: absolute !important;
  right: -16px;
  top: 100px;
  flex: none;
  width: 32px;
  height: 32px;
  border: 1px solid $gray-300;
  border-radius: 16px;
  background-color: $white;

  &::before {
    background-size: 13px;
    background-position: 8px 6px;
    background-image: $sidebar-toggler-indicator-icon !important;
  }

  &:hover {
    border-color: var(--cui-primary);
    background-color: var(--cui-primary);

    &::before {
      background-image: $sidebar-toggler-indicator-hover-icon !important;
    }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.sidebar-x {
  display: none;
  position: absolute;
  right: -2.5rem;
  top: 1.7rem;

  @include media-breakpoint-up(sm) {
    display: none;
  }
}
.sidebar.show .sidebar-x {
  display: inline-block;
}

.sidebar-brand-nav {
  padding: 0;
  height: 75px;

  .nav-group-items {
    height: 75px;
    visibility: visible !important;
    opacity: 1 !important;
    padding: 0 !important;
    margin: 0 !important;

    .nav-item {
      list-style: none;

      .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        margin: 0.6rem 0;
        border-radius: 4px;
        cursor: pointer;

        svg {
          path,
          rect,
          circle,
          polygon {
            fill: $white;
          }
        }

        &.active,
        &:hover {
          background-color: $white;
          border-radius: 4px;

          svg {
            path,
            rect,
            circle,
            polygon {
              fill: var(--cui-primary);
            }
          }
        }
      }
    }
  }
}

.sidebar-narrow-unfoldable .sidebar-toggler::before {
  background-position: 9px 7px;
}

.sidebar-narrow-unfoldable {
  padding-bottom: 0 !important;
  width: 10rem;

  .sidebar-header {
  }

  .sidebar-toggler {
    position: absolute !important;
  }

  .sidebar-brand-nav .nav-group-items {
    height: auto !important;
    visibility: visible !important;
    opacity: 1 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .sidebar-nav {
    .nav-link.nav-group-toggle {
      .nav-icon {
        padding-left: 0;
        flex: 0 0 5rem;
      }
      span {
        display: none;
      }
    }
  }
}

.sidebar-narrow-unfoldable .d-narrow-none,
.sidebar-narrow .d-narrow-none,
.sidebar-narrow-unfoldable .nav-label,
.sidebar-narrow .nav-label,
.sidebar-narrow-unfoldable .nav-title,
.sidebar-narrow .nav-title,
.sidebar-narrow-unfoldable .nav-group-items,
.sidebar-narrow .nav-group-items,
.sidebar-narrow-unfoldable .sidebar-footer,
.sidebar-narrow .sidebar-footer,
.sidebar-narrow-unfoldable .sidebar-form,
.sidebar-narrow .sidebar-form,
.sidebar-narrow-unfoldable .sidebar-header,
.sidebar-narrow .sidebar-header {
  height: 0 !important;
  padding: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;

  .nav-group {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .nav-link.nav-group-toggle {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: $gray-700;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;

    .nav-icon {
      padding-left: 1.2rem;
    }
  }

  .nav-item {
    .nav-link {
      font-size: 0.875rem;
      font-weight: 300;
      letter-spacing: 0.5px;
      margin-left: 2rem;
      padding-left: 1rem !important;
      color: $gray-700;
      border-left: 1px solid $gray-300;

      &.active {
        font-weight: 500;
        color: var(--cui-primary);
        border-left: 2px solid var(--cui-primary);
      }
      &:hover {
        color: var(--cui-primary);
      }
    }
  }
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1029;
}

.badge.badge-header-dropdown {
  opacity: 0.5;
  margin-right: 6px;
}

.no-shadow {
  box-shadow: none !important;
}

.dropdown {
  .caret-double::after {
    display: inline-block !important;
    width: 28px;
    height: 14px;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='14' viewBox='0 0 7 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.8225 4L3.5 1.52767L6.1775 4L7 3.23887L3.5 0L0 3.23887L0.8225 4Z' fill='%237B8B99'/%3E%3Cpath d='M6.1775 10L3.5 12.4723L0.8225 10L0 10.7611L3.5 14L7 10.7611L6.1775 10Z' fill='%237B8B99'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform 0.15s;
  }

  &:hover .caret-double::after {
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='14' viewBox='0 0 7 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.8225 4L3.5 1.52767L6.1775 4L7 3.23887L3.5 0L0 3.23887L0.8225 4Z' fill='%23000000'/%3E%3Cpath d='M6.1775 10L3.5 12.4723L0.8225 10L0 10.7611L3.5 14L7 10.7611L6.1775 10Z' fill='%23000000'/%3E%3C/svg%3E%0A");
  }
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--cui-primary);
}
.dropdown-item {
  cursor: pointer;
}

.card {
  .card-body {
    padding: 2rem 2rem;
  }
}

.btn-link {
  color: var(--cui-primary);
}
.form-multi-select-option.form-multi-selected {
  background-color: var(--cui-primary);
}

.btn.btn-primary {
  color: #fff;
  background-image: linear-gradient(var(--cui-primary), var(--cui-primary));

  &.arrow-right {
    padding-right: 40px;
    background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(255,255,255,0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        no-repeat right $input-btn-padding-x-lg center,
      linear-gradient(var(--cui-primary-light), var(--cui-primary));
  }

  &.arrow-left {
    padding-left: 40px;
    background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='rotate(180 8.5 7.5)'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(255,255,255,0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A")
        no-repeat left $input-btn-padding-x-lg center,
      linear-gradient(var(--cui-primary-light), var(--cui-primary));
  }

  &:hover {
    color: #fff;
    opacity: 0.9;
  }
}

.btn.btn-outline-light {
  &.arrow-left {
    padding-left: 40px;
    background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='rotate(180 8.5 7.5)'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(192, 208, 223, 0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='rgba(192, 208, 223, 1)' stroke-width='2' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A")
        no-repeat left $input-btn-padding-x-lg center,
      transparent;

    &:hover {
      // background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='rotate(180 8.5 7.5)'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(0,0,0, 0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat left $input-btn-padding-x-lg center, transparent;
    }
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  border-color: $gray-300 !important;
}

// Recharts

.recharts-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.recharts-legend-item {
  padding: 5px 20px;
  background-color: $body-bg;
  border-radius: 20px;
  margin: 5px 0px;
}

.recharts-default-tooltip {
  border: 1px solid $gray-300;
  background: $white;
  color: $high-emphasis;
}

.recharts-text tspan,
.recharts-legend-item-text {
  color: $high-emphasis !important;
  fill: $high-emphasis !important;
  font-family: 'Lato';
  font-size: 0.8rem;
}

.recharts-wrapper {
  text-align: center;
}

.rc-slider-handle:active {
  box-shadow: none !important;
}
.chart-legend-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 10px;
  background-color: $high-emphasis;
}

.chart-legend-item {
  border-radius: 4px;
  border-left: 4px solid var(--cui-primary);
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  margin: 10px 15px;

  .chart-legend-title {
    font-size: $font-size-sm;
    color: $high-emphasis;
  }
  .chart-legend-value {
    font-size: $h4-font-size;
    font-weight: 600;
  }
}

.page-link {
  cursor: pointer;
  color: var(--cui-primary);
}
.page-item.active .page-link {
  background-color: var(--cui-primary);
  border-color: var(--cui-primary);
}

.card-app-primary {
  background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(255,255,255,0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      no-repeat right $input-btn-padding-x-lg center,
    linear-gradient(var(--cui-primary-light), var(--cui-primary));
  border-radius: $border-radius;
  // border: 1px solid $gray-300;
  padding-right: 3rem;
  cursor: pointer;

  &:hover {
    background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(255,255,255,0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        no-repeat right $input-btn-padding-x-lg center,
      linear-gradient($medium-emphasis, $high-emphasis);
    // border-color: var(--cui-primary);
  }
}

.card-app-gray {
  background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(255,255,255,0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      no-repeat right $input-btn-padding-x-lg center,
    $gray-300;
  border-radius: $border-radius;
  // border: 1px solid $gray-300;
  padding-right: 3rem;
  cursor: pointer;

  &:hover {
    background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(255,255,255,0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        no-repeat right $input-btn-padding-x-lg center,
      linear-gradient(var(--cui-primary-light), var(--cui-primary));
    // border-color: var(--cui-primary);
  }
}

.card-app {
  background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(0,0,0,0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='black' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      no-repeat right $input-btn-padding-x-lg center,
    $gray-100;
  border-radius: $border-radius;
  // border: 1px solid $gray-300;
  padding-right: 3rem;
  cursor: pointer;

  &:hover {
    background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.5L5.96046e-08 7.5' stroke='rgba(255,255,255,0.4)' stroke-width='2'/%3E%3Cpath d='M9.93103 1.5L16 7.5L9.93103 13.5' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        no-repeat right $input-btn-padding-x-lg center,
      linear-gradient(var(--cui-primary-light), var(--cui-primary));
    // border-color: var(--cui-primary);
  }
}

.card-app-install {
  background: url("data:image/svg+xml,%3Csvg width='15px' height='17px' viewBox='0 0 15 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpolygon id='Path' fill='rgba(0,0,0,0.4)' points='9.67241379 7.32758621e-05 4.98275862 7.32758621e-05 4.98275862 5.27593534 0.586206897 5.27593534 0.586206897 5.86214224 7.32077155 12.6034483 14.0689655 5.86214224 14.0689655 5.27593534 9.67241379 5.27593534'%3E%3C/polygon%3E%3Crect fill='rgba(0,0,0,1)' id='Rectangle' x='0' y='15.2413793' width='14.6551724' height='1.75862069'%3E%3C/rect%3E%3C/svg%3E")
      no-repeat right $input-btn-padding-x-lg center,
    $gray-100;
  border-radius: $border-radius;
  // border: 1px solid $gray-300;
  padding-right: 3rem;
  cursor: pointer;

  &:hover {
    background: url("data:image/svg+xml,%3Csvg width='15px' height='17px' viewBox='0 0 15 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpolygon id='Path' fill='rgba(255,255,255,0.4)' points='9.67241379 7.32758621e-05 4.98275862 7.32758621e-05 4.98275862 5.27593534 0.586206897 5.27593534 0.586206897 5.86214224 7.32077155 12.6034483 14.0689655 5.86214224 14.0689655 5.27593534 9.67241379 5.27593534'%3E%3C/polygon%3E%3Crect fill='rgba(255,255,255,1)' id='Rectangle' x='0' y='15.2413793' width='14.6551724' height='1.75862069'%3E%3C/rect%3E%3C/svg%3E")
        no-repeat right $input-btn-padding-x-lg center,
      linear-gradient(var(--cui-primary-light), var(--cui-primary));
    // border-color: var(--cui-primary);
  }
}

.card-app-disabled {
  background: url("data:image/svg+xml,%3Csvg width='17px' height='17px' viewBox='0 0 17 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='rgba(153,172,188,0.4)' d='M8.5,0 C3.80559167,0 0,3.80559167 0,8.5 C0,13.1944083 3.80559167,17 8.5,17 C13.1944083,17 17,13.1944083 17,8.5 C17,3.80559167 13.1944083,0 8.5,0 Z M8.78333333,2.26666667 L10.4833333,2.26666667 L10.4833333,3.96666667 L8.78333333,3.96666667 L8.78333333,2.26666667 Z M11.3520687,13.0003958 C10.9318932,13.7228379 10.1590409,14.1671237 9.32329583,14.166667 C9.2953875,14.166667 9.26744375,14.1661708 9.23946458,14.1651792 C8.51420268,14.1395174 7.84151816,13.7800846 7.41707775,13.1914304 C6.99263735,12.6027763 6.86409778,11.8509957 7.06884792,11.1547625 L8.15036667,7.47748542 C8.23788944,7.18051843 8.07774053,6.86698367 7.78583776,6.76382162 C7.493935,6.66065958 7.17234171,6.80394069 7.05383125,7.08995625 L6.70108125,7.93995625 L5.13091875,7.28828958 L5.48366875,6.43828958 C5.94866329,5.31810644 7.20857926,4.75725207 8.35212806,5.1613886 C9.49567686,5.56552512 10.1234152,6.79348705 9.78130417,7.95709792 L8.69978542,11.634375 C8.60728633,11.9492512 8.76434463,12.2836357 9.06573711,12.4135035 C9.3671296,12.5433713 9.71804152,12.4278673 9.883375,12.144375 L10.3154583,11.4027146 L11.7841875,12.2587354 L11.3520687,13.0003958 Z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat right $input-btn-padding-x-lg center,
    $gray-100;
  border-radius: $border-radius;
  // border: 1px solid $gray-300;
  padding-right: 3rem;
  cursor: pointer;
}

.svg-fill-primary {
  fill: var(--cui-primary);
}
.svg-fill-secondary {
  fill: var(--cui-secondary);
}
.svg-stroke-primary {
  stroke: var(--cui-primary);
}
.svg-stroke-secondary {
  stroke: var(--cui-secondary);
}

.form-check-input:checked {
  background-color: var(--cui-primary);
  border-color: var(--cui-primary);
}

table.table tbody td {
  color: $high-emphasis;
}
.form-multi-select:not(.form-multi-select-selection-tags) .form-multi-select-search {
  width: 100%;
}

.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

.dateTimePicker {
  .react-datetime-picker__wrapper {
    border: 0;
  }
  input {
    color: inherit;
  }
  .dateTimePickerCalendar,
  .react-datetime-picker__clock {
    border-radius: 3px;
    border-color: $gray-300 !important;
  }
  .react-datetime-picker__calendar,
  .react-datetime-picker__clock {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    margin-left: -1px !important;
  }
  .react-calendar__tile {
    padding: 0.5em 0.2em;
  }
  .react-calendar__navigation {
    margin-bottom: 0;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: $gray-300;
  }
  .react-calendar__month-view__days__day--weekend {
    color: $gray-700;
  }
  .react-calendar__tile--now,
  .react-calendar__tile--now {
    background-color: $gray-100;
  }
  .react-clock__face {
    border: 0;
  }
  .react-datetime-picker__inputGroup__input:invalid {
    background: transparent;
  }
  .react-datetime-picker__inputGroup__input:active {
    border-color: transparent;
  }
  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: var(--cui-primary);
    color: #fff;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.2em;
  }
  abbr[title] {
    text-decoration: none;
  }
}
.form-label {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.form-label.col-auto.col-form-label {
  margin-top: 0;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-bold {
  font-weight: bold;
}

.text-select {
  color: hsl(0, 0%, 50%) !important;
}

.dark-theme {
  .text-dark {
    color: rgba(255, 255, 255, 0.87) !important;
  }
}

.studio-aside-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background: var(--cui-aside-handle-bg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

form .nav.nav-underline .nav-link {
  @include media-breakpoint-down(sm) {
    padding: 0.5rem 0.5rem;
  }
}

.uploading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 4px;
}

td.py-2.px-0 .btn.btn-ghost-link.btn-sm {
  padding: 0.25rem 0.25rem !important;
}

td.py-2.px-0 a {
  margin: 0 !important;
  padding: 0 !important;
}

.input-group-text {
  justify-content: center;
}
