$table-content-padding: 4px;

.p-tree {
  border: none;
  background: transparent;
  color: $gray-900;
  padding: 0;
  border-radius: unset;

  .p-tree-loading-overlay {
    background-color: rgba(0, 0, 0, 0.05);
    transition-duration: 0.2s;
    border-radius: 0.25rem;
  }

  .p-tree-container {
    .p-treenode {
      .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background-color: transparent;
        .p-treenode-label {
          color: $primary;
        }
      }

      .p-treenode-content.p-treenode-selectable.p-highlight {
        background-color: transparent;
        .p-treenode-label {
          color: $primary;
        }
      }

      .p-treenode-content.p-highlight {
        .p-tree-toggler {
          color: $gray-900;
        }
      }

      .p-treenode-content {
        font-size: 0.875rem;
        font-weight: bold;
        border-radius: 0;
        padding: 0.5rem 0;

        &:focus {
          box-shadow: none;
        }

        .p-treenode-icon {
          color: $gray-900;
          font-size: 0.875rem;
          font-weight: bold;
        }

        .p-tree-toggler {
          width: auto;
          height: auto;
          margin: 0;
          padding: 0 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: $gray-900;

          &:enabled:hover {
            color: $gray-900;
            background: transparent;
          }

          &:focus {
            box-shadow: none;
          }

          .p-tree-toggler-icon {
            font-size: 0.625rem;
          }
        }

        .p-treenode-label {
          cursor: pointer;
          transition: color 0.2s;
          line-height: 1.2;
          color: $gray-900;
        }
      }

      .p-treenode-children {
        padding: 0;
        .p-treenode {
          padding: 0 0 0 0.75rem;
        }
      }
    }
  }
}

.p-treetable .emporix-tree-table {
  padding: 0 10px;
  background: white;
  .p-treetable-thead {
    > tr > th {
      background: white;
      padding: $table-content-padding;
    }
  }

  .p-treetable-tbody {
    > tr > td {
      font-size: 14px;
      padding: $table-content-padding;
    }
  }
}

.emporix-tree-table--row {
  font-size: 14px;
}

.p-inputtext {
  padding: 0 0.5rem;
  border-radius: 0.15rem;
}

.p-paginator {
  justify-content: flex-end;

  .p-paginator-element,
  .p-paginator-pages .p-paginator-page {
    font-size: 0.8rem;
    margin: 0;
    border: 1px solid var(--gray-200);
    padding: 0.375rem;
    border-radius: 0;
    width: 32px !important;
    height: 35px !important;
    min-width: unset;
    color: var(--primary-color);

    &.p-highlight {
      color: white;
      background: var(--primary-color);
    }
  }

  .p-dropdown {
    height: 35px;
    border-radius: 3px;
    border: 1px solid var(--gray-200);

    .p-dropdown-label {
      display: flex;
      align-items: center;
    }
  }
}

.p-hidden-space {
  display: none;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}
.p-datatable.p-datatable-sm.emporix-data-table, .p-treetable.emporix-tree-table {
  border: 1px solid #C0D0DF;
  .p-datatable-thead {
    > tr > th {
      background: white;
    }
  }
}
